@import url('https://fonts.googleapis.com/css2?family=Lato&family=Courgette&family=Carter+One&display=swap');

* {
  /* user-select: none; */
  font-family: 'Lato', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
